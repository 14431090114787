
import {defineComponent, ref} from 'vue'
import {useI18n} from "vue-i18n";
import {SatisfactionGuaranteeService} from "@/service/SatisfactionGuaranteeService";
import {store} from "@/store";
import {ConfigFileService} from "@/service/ConfigFileService";
import {systemDomain} from "@/main";


export default defineComponent({
  name: 'WarrantyExtensionCertificateDownloads',
  setup() {
    const {t} = useI18n()
    const loading = ref(true)
    const errorMessage = ref<string>("")
    const retoureId = ref<string>("")
    const retoureNumber = ref<string>("")
    const itemStatusNo = ref<number>(0)
    const fileName = ref<string>("Transport-Dokument.pdf")
    const showFormular = ref<boolean>(false);
    const donloadObjectJson = localStorage.getItem("satisfaction-guarantee-download")

    if (donloadObjectJson) {
      const donloadObject = JSON.parse(donloadObjectJson)
      if (donloadObject.retoureId) {
        retoureId.value = donloadObject.retoureId
      }
      if (donloadObject.retoureNumber) {
        retoureNumber.value = donloadObject.retoureNumber
      }
      if (donloadObject.itemStatusNo) {
        itemStatusNo.value = parseInt(donloadObject.itemStatusNo)
      }
      if (donloadObject.fileName) {
        fileName.value = donloadObject.fileName
      }
      showFormular.value = true
    }

    function downloadLabel(retoureId: string) {
      errorMessage.value = ""
      SatisfactionGuaranteeService.downloadLabel(retoureId)
          .then(value => {
            downloadPdf(fileName.value, value)
          })
          .catch(error => {
            console.error(error)
            errorMessage.value = t('default error message')
          })
    }

    const loginUser = (): void => {
      const loginUrl = localStorage.getItem('einhell-login-data')
      if (loginUrl) {
        const uri = JSON.parse(loginUrl)
        window.open(uri.loginUrl, '_self')
      }
    }

    function downloadSafetyNote() {
      const fileURL = ConfigFileService.satisfactionGuaranteeServiceUrl + "assets/documents/sicherheitshinweis.pdf"
      var a = document.createElement("a");
      a.href = fileURL;
      a.target = "_blank"
      a.download = "sicherheitshinweis.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(fileURL);
      a.remove()
    }

    // eslint-disable-next-line no-undef
    function downloadPdf(filename: string, data: BlobPart) {
      var a = document.createElement("a");
      let file = new Blob([data]);
      const fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(fileURL);
      a.remove()
    }

    return {
      t,
      loading,
      errorMessage,
      retoureId,
      retoureNumber,
      itemStatusNo,
      fileName,
      showFormular,
      store,
      systemDomain,
      downloadLabel,
      downloadSafetyNote,
      loginUser
    }
  }
})
