
import { defineComponent } from 'vue';
import SatisfactionGuaranteeDownload from "@/components/SatisfactionGuaranteeDownload.vue";

export default defineComponent({
  name: 'App',
  components: {
    SatisfactionGuaranteeDownload,
  }
});
