import axios from "axios";
import {ConfigFileService} from "@/service/ConfigFileService";
import {KeycloakService} from "@/service/KeycloakService";

class InternalSatisfactionGuaranteeService {
    async downloadLabel(retoureId:string): Promise<string> {
        const token = KeycloakService.token
        const url = ConfigFileService.satisfactionGuaranteeServiceUrl + `api/satisfaction-gurantee/label/${retoureId}/download`

        const axiosInstance = axios.create({
            headers: {
                "Authorization": "Bearer " + token
            },
            responseType: "blob"
        })

        const res = await axiosInstance.get(url)
            .catch(function (error){
                throw error.response.data
            })

        return res.data
    }
}

export const SatisfactionGuaranteeService = new InternalSatisfactionGuaranteeService()