class InternalConfigFileService {
    private _satisfactionGuaranteeService: string | null = null

    async init(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.vueAppSatisfactionGuaranteeService == undefined) {
            throw new Error('Could not init application')
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._satisfactionGuaranteeService = window.vueAppSatisfactionGuaranteeService
    }

    get satisfactionGuaranteeServiceUrl(): string {
        if (this._satisfactionGuaranteeService) {
            return this._satisfactionGuaranteeService
        } else {
            throw new Error('Could not init application')
        }
    }
}

export const ConfigFileService = new InternalConfigFileService()
